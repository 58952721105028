const MethodPage = () => {
    
    <button type="button" className="btnClose" onClick={closeModal}>BACK</button>
  const closeModal = ({setIsOpen}) => {
    setIsOpen(false)
  }

  return (
    <div className="method">
        <div className="methodText textType01">
            the <span>union</span> of today and <span>Tomorrow</span>
        </div>
        <div class="methodVisual">
            <div className="img">
              <img src="images/common/img-item01.png" alt="" />
            </div>
            <div className="visualPopup">
                <strong>FUTURERA</strong>
                <div className="methodCt">
                    <p className="en">Futurera is a startup studio that embodies the union of today and tomorrow. Our vision is to disrupt the established practices of the past 100 years and restructure it for the next 100.</p>
                    <p className="ko">오늘과 내일의 연합, 스타트업 스튜디오 퓨쳐라의 비전은 과거 100년의 토착화 된 산업구조를 미래 100년의 산업구조로 새롭게 설계하는 것입니다.</p>
                </div>
            </div>
            <div className="methodCont">
                <div>
                    <p className="en">With a contemporary approach, we redefine long entrenched value chains to breathe life into a red ocean ecosystem and unlock a new blue ocean. Our goals are to employ our protocols to ‘Game Changers’ that will stimulate an era of growth and prosperity for the future.</p>
                    <p className="ko">오랜 시간 동안 형성된 밸류체인을 새로운 시각으로 재조합하여, 레드오션이 되어 버린 산업의 생태계에 새로운 생명력을 불어넣고, 블루오션으로의 변화를 이끌어냅니다. 퓨쳐라의 프로토콜을 부여하고, 미래 100년을 풍요롭게 할 ‘게임체인저’를 목표합니다.</p>
                </div>
                <div>
                    <p className="en">Essentially, we create companies while curtailing the risks. How do we do this? With pools of experts and diverse partners from different industries that have aligned interests to boost success. New projects are initiated from within, get developed and eventually becomes its own entity. You have the opportunity to not only participate but to take ownership. </p>
                    <p className="ko">퓨쳐라는 다양한 산업 분야의 전문가들과 파트너들이 성공을 위해 프로젝트에 함께 참여함으로써, 실패 가능성을 줄이면서 창업합니다. 퓨쳐라 내부에서 새 프로젝트가 시작되고 개발되며, 최종적으로 새로운 자체적인 회사가 됩니다. 여러분은 프로젝트에 참여할 뿐만 아니라 오너십을 가질 기회가 있습니다.</p>
                </div>
            </div>
            <div className="contact">
                <strong>The choice is <span>yours.</span></strong>
                <a href="mailto:woosup@futurera.net">TAKES MY HAND</a>
                <button type="button" className="btnClose" onClick={closeModal}>BACK</button>
            </div>
        </div>

    </div>
  )
}

export default MethodPage