const UnionModal = () => {
  return (
    <>
        <div className="unionContent">
            <div className="unionHeader">
                <strong><span>UNION</span></strong>
            </div>
            <div className="partners">
                <div className="ptn fp">
                    <a href="https://futureplay.co" target="_blank" title="새창열림"><img src="images/common/img-fb.png" alt="Futureplay" /></a>
                </div>
                <div className="ptn jr">
                    <a href="http://jonesnrocket.com/" target="_blank" title="새창열림"><img src="images/common/img-jr.png" alt="Jones&rocket investment" /></a>
                </div>
                <div className="ptn of">
                    <a href="https://ofotd.com/" target="_blank" title="새창열림"><img src="images/common/img-ofotd.png" alt="Oscar Futurera" /></a>
                </div>
                <div className="ptn lsg">
                    <a href="https://lesugiatelier.com/" target="_blank" title="새창열림"><img src="images/common/img-lsg.png" alt="lesugiatelier" /></a>
                </div>
            </div>
            <div className="partners">
                <div className="ptn mmt">
                    <a href="https://www.mymusictaste.com/" target="_blank" title="새창열림">
                        <img src="images/common/img-mmt.png" alt="MY MUSIC TASTE" />
                    </a>
                </div>
                <div className="ptn av">
                    <a href="https://www.ascendovc.com/" target="_blank" title="새창열림"><img src="images/common/img-av.png" alt="Ascendo Ventures" /></a>
                </div>
                <div className="ptn mj">
                    <a href="http://mokjungwook.com/" target="_blank" title="새창열림"><img src="images/common/img-mjw.png" alt="Mok Jungwook" /></a>
                </div>
                {/* <div className="ptn bb">
                    <a href="https://buyandbelieve.com/" target="_blank" title="새창열림"><img src="images/common/img-bb.png" alt="Buy&amp;Believe" /></a>
                </div> */}
            </div>
            <div className="partners">
                <div className="ptn kratio">
                    <a href="https://krat.io/" target="_blank" title="새창열림"><img src="images/common/img-kratio.png" alt="Kratio" /></a>
                </div>
                <div className="ptn tgp">
                    <a href="#"><img src="images/common/img-tg.png" alt="Tantan Global Partners" /></a>
                </div>
                <div className="ptn seg">
                    <a href="#"><img src="images/common/img-seg.png" alt="saturday.engineering" /></a>
                </div>
            </div>
            <div className="unionBottom">

            </div>
        </div>
    </>
  )
}

export default UnionModal