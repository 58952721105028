const ArchivesModal = () => {
  return (
    <>
    <div className="archivesContent">
        <div className="archives_left">
            <strong>ARCHIVES</strong>
            <div class="ar-img">
                <img src="images/common/img-item05.png" alt="" />
            </div>
        </div>
        <div className="archives_right">
            <div>
                <div className="futreaNews">
                    <div className="imgBox">
                        <strong>FUTURERA FEATURED</strong>
                    </div>
                    <ul>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span>2021.10.12</span>
                                </div>
                                <div className="newsContent">
                                    조선일보_[스타트업] 퓨처라, EDM 리듬에 춤추다 만난 창업자에게 나는 결국 투자했다.
                                </div>
                                <div className="newsTitle">
                                    CHOSUN
                                </div>
                                <a target="_blank" href="https://www.chosun.com/economy/smb-venture/2021/07/22/Z2IFLCVUANH4ZLKVS3EZU4MM6Y/?utm_source=daum&utm_medium=referral&utm_campaign=daum-news" title="새창열림">조선일보_[스타트업] 퓨처라, EDM 리듬에 춤추다 만난 창업자에게 나는 결국 투자했다.</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span>2021.01.20</span>
                                </div>
                                <div className="newsContent">
                                    매일경제_퓨처플레이 퓨쳐라 상호 지분 투자
                                </div>
                                <div className="newsTitle">
                                    MK NEWS
                                </div>
                                <a target="_blank" href="https://mirakle.mk.co.kr/view.php?year=2021&no=62968" title="새창열림">매일경제_퓨처플레이 퓨쳐라 상호 지분 투자</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span>2021.01.20</span>
                                </div>
                                <div className="newsContent">
                                    이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"
                                </div>
                                <div className="newsTitle">
                                    EDAILY
                                </div>
                                <a target="_blank" href="https://www.edaily.co.kr/news/read?newsId=01895846628919688&mediaCodeNo=257&OutLnkChk=Y" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="futreaNews unionNews">
                    <div className="imgBox">
                        <strong>UNION FEATURED</strong>
                    </div>
                    <ul>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="ofotd">OFOTD</span> <span>2022.12.07</span>
                                </div>
                                <div className="newsContent">
                                    K-POP과 K패션이 만났다
                                </div>
                                <div className="newsTitle">
                                    MK NEWS
                                </div>
                                <a target="_blank" href="https://www.mk.co.kr/news/it/10558939?utm_source=naver&utm_medium=referral&utm_campaign=relatednews" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="ofotd">OFOTD</span> <span>2022.11.28</span>
                                </div>
                                <div className="newsContent">
                                    OFOTD 리미티드 에디션 민팅 완판…"실물 굿즈로 생태계 확장"
                                </div>
                                <div className="newsTitle">
                                    Hankyung
                                </div>
                                <a target="_blank" href="https://www.hankyung.com/finance/article/202211280414B" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="bb">Buy &amp; Believe</span> <span>2022.07.30</span>
                                </div>
                                <div className="newsContent">
                                    DRIVERS: 허재영 & 1987 메르세데스-벤츠 560 SL
                                </div>
                                <div className="newsTitle">
                                    HYPEBEAST
                                </div>
                                <a target="_blank" href="https://hypebeast.kr/2022/7/drivers-jae-huh-1987-mercedes-benz-560-sl-interview" title="새창열림">DRIVERS: 허재영 & 1987 메르세데스-벤츠 560 SL</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="lsg">lesugiatelier</span> <span>2022.06.21</span>
                                </div>
                                <div className="newsContent">
                                    르수기아뜰리에 2023 리조트 컬렉션 룩북 공개
                                </div>
                                <div className="newsTitle">
                                    HYPEBEAST
                                </div>
                                <a target="_blank" href="https://hypebeast.kr/2022/6/lesugiatelier-2023-resort-collection-lookbook" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="jr">jones &amp; rocket</span> <span>2022.05.22</span>
                                </div>
                                <div className="newsContent">
                                    [fn이사람] “돈만 쏴주는 투자자 되기 싫어… 스타트업 확실히 뒷받침해줄것”
                                </div>
                                <div className="newsTitle">
                                    FN News
                                </div>
                                <a target="_blank" href="https://www.fnnews.com/news/202205221847168848" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="ofotd">OFOTD</span> <span>2022.05.06</span>
                                </div>
                                <div className="newsContent">
                                    ZOZOTOWN_韓国発メタファッションショールーム「OFOTD」
                                </div>
                                <div className="newsTitle">
                                    fashion tech
                                </div>
                                <a target="_blank" href="https://fashiontechnews.zozo.com/philosophy/ofotd" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                        <li>
                            <div class="newsBox">
                                <div className="tags">
                                    <span className="ofotd">OFOTD</span> <span>2022.03.16</span>
                                </div>
                                <div className="newsContent">
                                    디지털투데이_오스카퓨쳐라, 프리 시리즈A 투자 유치
                                </div>
                                <div className="newsTitle">
                                    digital today
                                </div>
                                <a target="_blank" href="http://www.digitaltoday.co.kr/news/articleView.html?idxno=437697" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ArchivesModal