import { createBrowserRouter } from "react-router-dom";

import Main from './routes/main'
import MethodPage from './routes/method'
import Union from './routes/union'
import Archives from "./routes/archives";
import Connect from './routes/contact';
import Layout from "./routes/Layout";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [{
            path: '/',
            element: <Main />
        },
        {
            path: '/method',
            element: <MethodPage />
        },
        {
            path: '/union',
            element: <Union /> 
        },
        {
            path: '/archives',
            element: <Archives />
        },
        {
            path: '/contact',
            element: <Connect />
        }]
    }
])

export default router;