const union = () => {
  return (
    <div className="union">
        <div className="textType01">
            <span>UNION</span> STRONG
        </div>
        <div className="partners">
            <div className="ptn fp">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-fb.png" alt="Futureplay" /></a>
                </div>
                <div className="cp-name">
                    <span>Futureplay</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn tgp">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-tgp.png" alt="Tantan Global Partners" /></a>
                </div>
                <div className="cp-name">
                    <span>Tantan Global Partners</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn jr">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-jr.png" alt="Jones&rocket investment" /></a>
                </div>
                <div className="cp-name">
                    <span>Jones&rocket investment</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn of">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-fb.png" alt="Oscar Futurera" /></a>
                </div>
                <div className="cp-name">
                    <span>Oscar Futurera</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn lsg">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-lsg.png" alt="lesugiatelier" /></a>
                </div>
                <div className="cp-name">
                    <span>lesugiatelier</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn kratio">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-kratio.png" alt="Kratio" /></a>
                </div>
                <div className="cp-name">
                    <span>Kratio</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn av">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-av.png" alt="Ascendo Ventures" /></a>
                </div>
                <div className="cp-name">
                    <span>Ascendo Ventures</span>
                </div>
            </div>
        </div>
        {/* <div className="partners">
            <div className="ptn bb">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-bb.png" alt="Buy&amp;Believe" /></a>
                </div>
                <div className="cp-name">
                    <span>Buy&amp;Believe</span>
                </div>
            </div>
        </div> */}
        <div className="partners">
            <div className="ptn mj">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-mj.png" alt="Mok Jungwook" /></a>
                </div>
                <div className="cp-name">
                    <span>Mok Jungwook</span>
                </div>
            </div>
        </div>
        <div className="partners">
            <div className="ptn seg">
                <div className="logoImg">
                    <a href="#"><img src="images/common/img-seg.png" alt="saturday.engineering" /></a>
                </div>
                <div className="cp-name">
                    <span>saturday.engineering</span>
                </div>
            </div>
        </div>
        <div className="contact">
            <strong>The choice is <span>yours.</span></strong>

            <a href="/contact">TAKES MY HAND</a>
        </div>
    </div>
  )
}

export default union