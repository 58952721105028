import React, { Component } from "react";
import MediaQuery from "react-responsive";
import $ from "jquery";
import { RouterProvider } from "react-router-dom";
import router from "./router";


export default class App extends Component {

  componentDidMount(){
    /* $(window).resize(function(){

      console.log( $(window).width() )
    }) */
/* 
    $(".menu").click(function(){
      $(this).siblings("ul").slideToggle();
    });


    $(".nav a").click(function(){
      var thisName = $(this).text();
      $(".nav button span").text(thisName);
      $(".nav > ul").slideUp();
    }); 

    $(".menu").click(function(){
      if ( $(this).hasClass("active") ) {
        $(this).removeClass("active");
        $("#contact").fadeOut();
      } else {
        $(this).addClass("active");
        $(".popup").hide();
        $("#contact").fadeIn();
      }
    });
    
    $(".popup .btnClose").click(function(){
      $(".menu").removeClass("active");
      $(".popup").fadeOut();
    });
    */

   /*  $(".mainItem .link button").hover(function(){
      console.log("d");
      $(this).parents(".textBox").siblings("img").css("transform", "rotate(-5deg)")
    }, function(){
      $(this).parents(".textBox").siblings("img").css("transform", "rotate(0deg)")
    });
 */
  }
  
  
  render() {
    return (
     <RouterProvider router={router} />
    );
  }
}
