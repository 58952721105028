import { useState } from "react";
import { AnimatePresence} from 'framer-motion'

import CommonModal from "../components/CommonModal";
import ContactModal from "../components/ContactModal";
import MethodModal from "../components/MethodModal";
import UnionModal from "../components/UnionModal";
import ArchivesModal from "../components/ArchivesModal";


import HorizontalScroll from 'react-scroll-horizontal'



const Layout = () => {
    const [modal, setModal] = useState('contact')
    const [isOpen, setIsOpen] = useState(false);
  
    const openModal = (name) => {
        setModal(name);
        setIsOpen(true);
    }

  
  return (
    <>
    <div id="wrap">
        <header className="header">
        <div className="hd">
            <div className="logo">
            <a href="/"><img src='images/common/logo.png' alt="futurera logo" /></a>
            </div>
            <nav className="nav">
                <button type="button" className="menu" onClick={() => openModal('contact')}><span>open</span></button>
            </nav>
        </div>
        </header>
        <main className="main" id="container">
            <div id="contents">
                <div className="futureraText pcView">
                    FUTUR<span>ERA</span>
                </div>
                <div className="mainBox">
                    <div className="mainItem">
                        <div className="item01">
                            <div className="textBox">
                                <div className="num">01.</div>
                                <div className="link"><button type="button" onClick={() => openModal('method')}>METHOD</button></div>
                            </div>
                            <div className="unionText">Union Strong.</div>
                            <img src="images/common/img-item01.png" alt="" />
                        </div>
                        <div className="item02">
                            <div className="textBox">
                                <div className="num">02.</div>
                                <div className="link"><button type="button">UNION</button></div>
                            </div>
                            <img src="images/common/img-item02.png" alt="" />
                        </div>
                        <div className="item03">
                            <div className="textBox">
                                <div className="num">03.</div>
                                <div className="link"><button type="button" onClick={() => openModal('archives')}>Archives</button></div>
                            </div>
                            <img src="images/common/img-item03.png" alt="" />
                        </div>
                    </div>
                    <div className="futureraText mobiView">
                        FUTUR<span>ERA</span>
                    </div>
                    <div className="mobift">
                        <address>8F, Acro Forest D Tower, 83-21, Wangsimni-ro, Seongdong-gu, Seoul, Republic of Korea (04769)</address>
                        <p className="copyright">2023© Copyright FUTURERA. All Rights Reserved.</p>
                    </div>
                </div>
                <AnimatePresence>
                    {isOpen && <CommonModal setIsOpen={setIsOpen} setName={modal} >
                    {modal === 'method' && <MethodModal setIsOpen={setIsOpen} />}
                    {modal === 'union' && <UnionModal setIsOpen={setIsOpen} />}
                    {modal === 'archives' && <ArchivesModal setIsOpen={setIsOpen} />}
                    {modal === 'contact' && <ContactModal setIsOpen={setIsOpen} />}
                    </CommonModal>}
                </AnimatePresence>
            </div>
        </main>
        <div className="footer">
            <div className="ft">
                <address>8F, Acro Forest D Tower, 83-21, Wangsimni-ro, Seongdong-gu, Seoul, Republic of Korea (04769)</address>
                <p className="copyright">2023© Copyright FUTURERA. All Rights Reserved.</p>
            </div>
        </div>
    </div>  
    </>
  )
}

export default Layout