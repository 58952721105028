const ContactModal = () => {
  return (
    <>
        <div className="contact">
            <div className="img">
                <img src="images/common/img-item04.png" alt="" />
            </div>
            <div className="link">
                <a href="mailto:woosup@futurera.net"><span>TAKE</span> MY HAND</a>
            </div>
        </div>
    </>
  )
}

export default ContactModal