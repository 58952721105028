import {motion} from 'framer-motion'

const CommonModal = ({children, setIsOpen, setName}) => {
  const addClass = setName + " popup";
  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <motion.div
        initial={{
            opacity: 0
        }}

        animate={{
            opacity: 1
        }}

        exit={{
            opacity: 0
        }}
    className={addClass}>
    <div className="popupContent">
        {children}
    </div>
    <button type="button" className="btnClose" onClick={closeModal}>BACK</button>
</motion.div>
  )
}

export default CommonModal