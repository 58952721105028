import { useState } from "react";
import CommonModal from "../components/CommonModal";
import MethodModal from "../components/MethodModal";
import UnionModal from "../components/ContactModal";
import ArchivesModal from "../components/ArchivesModal";


const Main = () => {
  const [modal, setModal] = useState('method')
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (name) => {
    setModal(name);
    setIsOpen(true);
  }

  return (
    <div>
        


       
    </div>
  )
  
}


export default Main

