const archives = () => {
  return (
    <div className="archives">
        <div className="textType01">
            <span>ARCHIVES</span>
        </div>
        <div className="futreaNews">
            <div className="imgBox">
                <strong>FUTURERA FEATURED</strong>
            </div>
            <ul>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            조선일보_[스타트업] 퓨처라, EDM 리듬에 춤추다 만난 창업자에게 나는 결국 투자했다.
                        </div>
                        <div className="newsTitle">
                            CHO SUN
                        </div>
                        <a href="#" title="새창열림">조선일보_[스타트업] 퓨처라, EDM 리듬에 춤추다 만난 창업자에게 나는 결국 투자했다.</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span>2021.01.20</span>
                        </div>
                        <div className="newsContent">
                            매일경제_퓨처플레이 퓨쳐라 상호 지분 투자
                        </div>
                        <div className="newsTitle">
                            mirakle
                        </div>
                        <a href="#" title="새창열림">매일경제_퓨처플레이 퓨쳐라 상호 지분 투자</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span>2021.01.20</span>
                        </div>
                        <div className="newsContent">
                            이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"
                        </div>
                        <div className="newsTitle">
                            EDAILY
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
            </ul>
        </div>
        <div className="unionNews">
            <div className="imgBox">
                <strong>Union FEATURED</strong>
            </div>
            <ul>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            K-POP과 K패션이 만났다
                        </div>
                        <div className="newsTitle">
                            매일경제
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="lsg">르수기아뜰리에</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            르수기아뜰리에 2023 리조트 컬렉션 룩북 공개
                        </div>
                        <div className="newsTitle">
                            HYPE BEAST
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            OFOTD 리미티드 에디션 민팅 완판…"실물 굿즈로 생태계 확장"
                        </div>
                        <div className="newsTitle">
                            Hankyung
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            디지털투데이_오스카퓨쳐라, 프리 시리즈A 투자 유치
                        </div>
                        <div className="newsTitle">
                            digital today
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            ZOZOTOWN_韓国発メタファッションショールーム「OFOTD」
                        </div>
                        <div className="newsTitle">
                            fashion tech
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            [fn이사람] “돈만 쏴주는 투자자 되기 싫어… 스타트업 확실히 뒷받침해줄것”
                        </div>
                        <div className="newsTitle">
                            FN News
                        </div>
                        <a href="#" title="새창열림">이데일리_퓨처플레이 "스타트업 스튜디오 `퓨쳐라`와 상호 지분투자"</a>
                    </div>
                </li>
                <li>
                    <div class="newsBox">
                        <div className="tags">
                            <span className="ofotd">ofotd</span> <span>2021.10.12</span>
                        </div>
                        <div className="newsContent">
                            DRIVERS: 허재영 & 1987 메르세데스-벤츠 560 SL
                        </div>
                        <div className="newsTitle">
                            HYPEBEAST
                        </div>
                        <a href="#" title="새창열림">DRIVERS: 허재영 & 1987 메르세데스-벤츠 560 SL</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default archives